<template>
  <a-table
    :columns="columns"
    :dataSource="list"
    :pagination="paginate"
    :row-key="(record) => record.id"
    :row-selection="{ onChange: onSelect }"
    @change="handleTableChange"
  >
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >
      {{ text }}
    </a>

    <span slot="name" slot-scope="record">
      <template v-if="record.ru.title.length > 50">
        {{ `${record.ru.title.slice(0, 50)}...` }}
      </template>
      <template v-else>
        {{ record.ru.title}}
      </template>
    </span>

    <span slot="date" slot-scope="date">
      {{ date }}
    </span>

    <div class="d-flex" slot="action" slot-scope="record">
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$router.push({ path: `/content/news/${record.id}`})">
        <i class="fe fe-edit mr-2" />
        Изменить
      </a>
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$router.push({ path: `/content/news/${record.id}`, query: { is_preview: 'true' }})">
        <small>
          <i class="fe fe-more-horizontal mr-2" />
        </small>
        Просмотр
      </a>
      <a-popconfirm
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="$emit('removeItem', record.id)"
      >
        <a href="javascript: void(0);" class="btn btn-sm btn-light d-flex align-items-center">
          <small>
            <i class="fe fe-trash mr-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </div>
  </a-table>
</template>

<script>
export default {
  name: 'NewsTable',

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Уникальный ID',
          dataIndex: 'unique_id',
        },
        {
          title: 'Дата создания',
          dataIndex: 'date_from',
          sorter: true,
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit('handleTableChange', pagination, filters, sorter)
    },

    onSelect(selectedRows) {
      this.$emit('selectRows', selectedRows)
    },
  },
}
</script>
